var escribiendoIntervalo; // Variable para almacenar el identificador del intervalo

export function activarEscribiendo() {
    var escribiendo = true; // Estado inicial

    // Inicia el intervalo que alterna los estados
    escribiendoIntervalo = setInterval(() => {
        if (escribiendo) {
            $('#estado').text('Gptina esta escribiendo...');
            escribiendo = false;

            // Después de un tiempo más largo, cambia a 'Online'
            setTimeout(() => {
                $('#estado').text('Online');
            }, 1800); // 'escribiendo...' se muestra por 1.8 segundos
        } else {
            escribiendo = true;

            // Después de un tiempo más corto, cambia a 'escribiendo...'
            setTimeout(() => {
                $('#estado').text('Gptina esta escribiendo...');
            }, 200); // 'Online' se muestra por 0.2 segundos
        }
    }, 2000); // Intervalo total de 2 segundos
}

export function desactivarEscribiendo() {
    // Detiene el intervalo
    clearInterval(escribiendoIntervalo);

    // Restablece el estado a 'Online'
    $('#estado').text('Online');
}