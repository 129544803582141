import {request} from './request.js'


function login(username, password, remember) {
    return request('/auth/login', 'POST', { username, password, remember });
}

function register(name, email, password) {
    return request('/auth/register', 'POST', { name, email, password });
}

function logout() {
    return request('/auth/logout', 'POST');
}

function checkAuthentication() {
    return request('/auth/checkAuthentication', 'GET');
}



let apiAuth = {
    login, register, logout, checkAuthentication
}

export default apiAuth;