// Importamos la función request de nuestro módulo de peticiones HTTP
import {request} from './request.js'

// Definimos una función que recibe el texto a procesar y lo envía al servidor
function getGroups() {
    // Asumimos que la ruta correcta para obtener los grupos de un usuario es '/user/groups'
    return request('/groups', 'GET');
}

// Definimos una función para crear un nuevo grupo
function createGroup(groupData) {
    // Asumimos que la ruta correcta para crear un grupo es '/groups'
    return request('/groups', 'POST', groupData);
}

// Definimos una función para eliminar un grupo por su ID
function deleteGroup(groupId) {
    // Asumimos que la ruta correcta para eliminar un grupo es '/groups/:groupId'
    return request(`/groups/${groupId}`, 'DELETE');
}

// Definimos una función para actualizar un grupo por su ID
function updateGroup(groupId, updates) {
    // Asumimos que la ruta correcta para actualizar un grupo es '/groups/:groupId'
    return request(`/groups/${groupId}`, 'PATCH', updates);
}

function getGroupWithMessages(groupId) {
    // Asumimos que la ruta correcta para obtener un grupo y sus actividades es '/groups/:groupId'
    return request(`/groups/${groupId}`, 'GET');
}

// Creamos un objeto que expone las funciones para ser usadas en otros módulos
let apiGroups = {
    getGroups,
    createGroup,
    deleteGroup,
    updateGroup,
    getGroupWithMessages,
};

// Exportamos el objeto para que pueda ser importado en otros archivos
export default apiGroups;