import { request } from './request.js';

function sendContactForm(name, email, subject, message, phone) {
    return request('/email/contact', 'POST', { name, email, subject, message, phone });
}

let apiContact = {
    sendContactForm
}

export default apiContact;