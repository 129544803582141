function escrituraLenta(elemento, textoCompleto, tiempo) {
    return new Promise((resolve, reject) => {
        // var elemento = $(selector);
        // var textoCompleto = elemento.text();
        var indice = 0;


        var escribirCaracter = function () {
            if (indice < textoCompleto.length) {
                elemento.append(textoCompleto.charAt(indice));
                indice++;
                setTimeout(escribirCaracter, tiempo);
            } else {
                resolve(); // Resuelve la promesa cuando se ha terminado de escribir todo el texto
            }
        };

        escribirCaracter();
    });
}

function obtenerHora(timestamp) {
    // Si no se proporciona un timestamp, usar la fecha y hora actual
    var fecha = timestamp ? new Date(timestamp * 1000) : new Date();

    // Obtener horas y minutos
    var horas = fecha.getHours();
    var minutos = fecha.getMinutes();

    // Asegurarse de que las horas y los minutos sean dos dígitos
    horas = horas < 10 ? '0' + horas : horas;
    minutos = minutos < 10 ? '0' + minutos : minutos;

    // Formatear la hora como "HH:MM"
    return horas + ':' + minutos;
}


export {escrituraLenta, obtenerHora};

