// Importamos la función request de nuestro módulo de peticiones HTTP
import {request} from './request.js'

// Definimos una función que recibe el texto a procesar y lo envía al servidor
function processText(text, threadId, groupId) {
    // Aquí definimos la ruta del endpoint al que queremos enviar los datos
    const endpoint = '/openai/process-text';

    // Creamos el objeto con los datos que vamos a enviar
    const textData = {
        text,
        threadId,
        groupId
    };

    // Hacemos la petición POST con los datos del texto
    return request(endpoint, 'POST', textData);
}
function processTextAnonimo(text, threadId) {
    // Aquí definimos la ruta del endpoint al que queremos enviar los datos
    const endpoint = '/openai/process-text-anonimo';

    // Creamos el objeto con los datos que vamos a enviar
    const textData = {
        text,
        threadId        
    };

    // Hacemos la petición POST con los datos del texto
    return request(endpoint, 'POST', textData);
}

// Creamos un objeto que expone la función para ser usada en otros módulos
let apiTextProcessing = {
    processText,
    processTextAnonimo
};

// Exportamos el objeto para que pueda ser importado en otros archivos
export default apiTextProcessing;