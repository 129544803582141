import apiAuth from './api_auth.js'

function mostarInfo_usuario(loginData) {
  $('#navItemLogin').hide();
  $('#navItemRegister').hide();
  $('#navItemExit').show();
  $('#liName').show().children('a').children('span').text(loginData.displayName || 'nombre no encontrado');
  if (loginData.photo) {
    $('#liName img').show().attr("src", loginData.photo);
    $('#liName i').hide();
  } else {
    $('#liName img').hide().removeAttr("src");
    $('#liName i').show();

  }
}

function mostrarInfo_publica() {
  $('#navItemLogin').show();
  $('#navItemRegister').show();
  $('#navItemExit').hide();
  $('#liName').hide().children('a').children('span').text('');
}

function habilitarLogin() {

  // login
  const loginModal = $('#loginModal');
  const loginForm = $("#loginForm");

  // let modal = new Modal(loginModal[0]);
  loginModal[0].addEventListener('shown.bs.modal', function () {
    loginForm.find('.error').hide();
    $('#loginInputEmail').val("");
    $('#loginInputPassword').val("");
    loginForm.removeClass('was-validated');
  });

  loginForm.on("submit", function (event) {
    event.preventDefault();
    if (loginForm[0].checkValidity()) {
      loginForm.removeClass('was-validated');

      const username = $('#loginInputEmail').val();
      const password = $('#loginInputPassword').val();
      const remember = $('#recordar').prop('checked');

      apiAuth.login(username, password, remember)
        .then(response => {
          // después de iniciar sesión exitosamente
          console.log("Iniciar sesión exitoso. ");
          // let strloginData = JSON.stringify(response);
          // localStorage.setItem("loginData", strloginData);

          // Ocultar el modal de inicio de sesión
          $('#loginModal .btn-close').trigger('click');// ocultar modal


          mostarInfo_usuario(response);

        }).catch(error => {
          // Manejar el error de inicio de sesión
          console.log("Error al iniciar sesión:", error);
          // Mostrar mensaje de error al usuario o realizar alguna acción específica según el tipo de error
          let errDiv = loginForm.find('.error').show();
          let autherror = error.data.autherror;
          if (autherror === 'servererror') {
            errDiv.text('Error en el servidor. Si el problema persiste contacte al desarrollador del software.');
          } else if (autherror === 'usuarioNoExiste') {
            errDiv.text('Usuario no existe. Compruebe que el correo electrónico es el que uso para registrarse y que esta bien escrito.')
          } else if (autherror === 'invalidPassword') {
            if (error?.data?.info?.tienePassword) {
              if (error?.data?.info?.tieneCuentaFederada) {
                errDiv.text('Contraseña incorrecta. También puede continuar con su cuenta de "' + error?.data?.info?.provider + "'");
              } else {
                errDiv.text('Contraseña incorrecta.');
              }
            } else { // no tiene Password, debería tieneCuentaFederada
              if (error?.data?.info?.tieneCuentaFederada) {
                errDiv.text('Intente continuar con su cuenta de "' + error?.data?.info?.provider + "'");
              } else {
                errDiv.text('Error de autenticación. Si el problema persiste contacte al desarrollador del software.');
              }
            }
          }

        });

    } else {
      loginForm.addClass('was-validated');
    }
  });
}

function habilitarRegister() {
    // register
    let registerModal = $('#registerModal');
    let registerForm = $("#registerForm");
  
    //registerModal.on('shown.bs.modal', function () { // no sé porque no funciona
    registerModal[0].addEventListener('shown.bs.modal', () => {
      registerForm.find('.error').hide();
      $('#registerInputName').val("");
      $('#registerInputEmail').val("");
      $('#registerInputPassword').val("");
      $('#registerInputPasswordConfirm').val("");
      registerForm.removeClass('was-validated');
    });
    $("#registerInputPassword, #registerInputPasswordConfirm").on("keyup", () => {
      let [pas1, pas2] = document.querySelectorAll("#registerInputPassword, #registerInputPasswordConfirm");
      (pas1.value === pas2.value) ? pas2.setCustomValidity("") : pas2.setCustomValidity("Mostar feedback");
    });
  
    let acceptTerms = $("#acceptTerms");
    acceptTerms.is(':checked') ? acceptTerms[0].setCustomValidity("") : acceptTerms[0].setCustomValidity("Mostar feedback");
    acceptTerms.on('change', function () {
      $(this).is(':checked') ? this.setCustomValidity("") : this.setCustomValidity("Mostar feedback");
    });
  
    $("#registerForm").on("submit", function (event) {
      event.preventDefault();
      if (registerForm[0].checkValidity()) {
        const name = $('#registerInputName').val();
        const email = $('#registerInputEmail').val();
        const password = $('#registerInputPassword').val();
        //const passwordConfirm = $('#registerInputPasswordConfirm').val();
  
        apiAuth.register(name, email, password)
          .then(response => {
            // Realizar acciones después de registrarse exitosamente
            console.log("Registro exitoso");
  
            registerModal.find('.btn-close').trigger('click');// ocultar modal
  
            // let strloginData = JSON.stringify(response);
            // localStorage.setItem("loginData", strloginData);
  
            mostarInfo_usuario(response);
  
          })
          .catch(error => {
            // Manejar el error de registro    
            let t;
            if (error?.data?.autherror === 'registerError') {
              if (error?.data?.info === 'usuarioFederado') {
                t = 'Ya existe una cuenta con este correo, intente continuar con "' + error?.data?.provider + '".';
              } else if (error?.data?.info === 'usuarioLocal') { // 
                t = 'Ya existe una cuenta con este correo, cierre este cuadro e intente ingresar con su contraseña.'
              } else {
                t = "Error al registrarse"
              }
            } else {
              t = "Error al registrarse"
              console.error(error.data.message);
            }
            registerForm.find('.error').show().text(t)
          });
  
      } else {
        registerForm.addClass('was-validated');
      }
  
    });
}

export { habilitarLogin, habilitarRegister, mostarInfo_usuario, mostrarInfo_publica }