// Import our custom CSS
import '../scss/styles.scss';

// Import only the Bootstrap components we need
import { Popover, Modal } from 'bootstrap';

import 'bootstrap-icons/font/bootstrap-icons.css';

// agregados por lf
// import $ from 'jquery'; // se carga globalmente mediante ProvidePlugin de webpack
import apiAuth from './api_auth.js';
import { habilitarLogin, habilitarRegister, mostarInfo_usuario as mostarInfo_usuario_en_navbar, mostrarInfo_publica as mostrarInfo_publica_en_navbar } from './navbar.js'


import { escrituraLenta, obtenerHora } from './utilidades.js';
import apiTextProcessing from './apiTextProcessing.js';
import apiGroups from './api_groups.js';
import apiContact from './api_contact.js';

import { activarEscribiendo, desactivarEscribiendo } from './estadoEscribiendoOnline.js';

import nuevo from '../img/grupos/nuevo.png';
import imgGrupo1 from '../img/grupos/1.jpeg';
import imgGrupo2 from '../img/grupos/7.jpeg';
import gptina from '../img/martina3.png'

import { getOrigen } from './request.js'

function removeURLParameters(paramsToRemove) {
  // Obtener la URL actual del navegador
  var url = window.location.href;

  // Verificar si la URL tiene parámetros
  if (url.indexOf('?') !== -1) {
    // Obtener los parámetros de la URL
    var params = url.split('?')[1].split('&');

    // Crear un nuevo array de parámetros sin los parámetros a eliminar
    var newParams = params.filter(function (param) {
      var paramName = param.split('=')[0];
      return !paramsToRemove.includes(paramName);
    });

    // Construir la nueva URL sin los parámetros a eliminar
    var newURL = url.split('?')[0] + '?' + newParams.join('&');

    // Reemplazar la URL actual del navegador con la nueva URL
    window.history.replaceState({}, document.title, newURL);
  }
}
// se revisan los parámetros de la URL
// Si hay un token se guarda____________________
// Obtener el token de la URL
const urlParams = new URLSearchParams(window.location.search);
const autherror = urlParams.get('autherror');
if (autherror) {
  MostrarModal1({
    Titulo: 'Error de autenticación',
    cuerpoHtml: '<p>Error al intentar la autenticación. Si el problema persiste contacte al desarrollador del software</p>',
    MostrarBotonCerrar: true,
    ocultarAlPresionarAfuera: true,
    BtnPrimaryText: 'Aceptar',
    BtnSecondaryText: undefined,
    OnBtnPrimaryClick: undefined,
  })
  removeURLParameters(["autherror", "info"]);
}

// Create an example popover
document.querySelectorAll('[data-bs-toggle="popover"]')
  .forEach(popover => {
    new Popover(popover)
  })

let threadId, groupId, activitiId;
$(function () { // una vez que la página está cargada

  // Establecer el valor de href para las etiquetas 'a' con los IDs dados
  $('#BtnRegisterFormGoogle').attr('href', getOrigen() + '/auth/google');
  $('#BtnloginModalGoogle').attr('href', getOrigen() + '/auth/google');


  $('#btnAtras').on('click', () => {
    // se muestran los grupos en lugar del chat
    $('#divListaGrupos').show();
    $('#divChat').hide();
  });

  threadId = undefined;
  groupId = undefined;
  activitiId = undefined;


  apiAuth.checkAuthentication()
    .then(function (response) {
      if (response.isAuthenticated) {
        mostarInfo_usuario(response);
      } else {
        mostrarInfo_publica();

      }
      $('body').show(); // Muestra el contenido de la página
    }).catch(e => {
      if (e instanceof TypeError && e.message === 'Failed to fetch') {
        // Es un error de tipo 'Failed to fetch'
        // MostrarModal1({
        //   Titulo: 'Ha ocurrido un error',
        //   cuerpoHtml: '<p>Lo sentimos, en estos momentos no podemos establecer conexión con el servidor, por favor intente en otro momento.</p>',
        //   MostrarBotonCerrar: true,
        //   ocultarAlPresionarAfuera: true,
        //   BtnPrimaryText: undefined,
        //   BtnSecondaryText: undefined,
        //   OnBtnPrimaryClick: undefined,
        // });
        mostrarInfo_publica(['¡Hola! Soy Gptina, tu futuro asistente virtual. Estoy en las etapas finales de mi desarrollo, muy pronto estaré operativa.']);
        $('#textAreaMensaje').attr("disabled", true);
        $('#buttonEnviar').attr("disabled", true);
        $('#labelTextAreaMensaje').text('No se pueden enviar mensajes...');
      } else {
        mostrarInfo_publica(['Lo siento, he tenido un error. Por favor, intenta más tarde.']);
      }
    }).finally(() => {
      $('body').show(); // Muestra el contenido de la página
    });



  habilitarLogin();
  habilitarRegister();
  // habilitarEnvioFormularioContacto();
  habilitarNuevoGrupo();


  // Salir
  $("#logout").on("click", function () {
    apiAuth.logout()
      .then(response => {
        if (response.logoutOk) {
          console.log('Logout exitoso en el servidor, eliminando datos en el cliente...');
          // mostrarInfo_publica_en_navbar();
          mostrarInfo_publica();
        } else {
          console.error('Error al hacer logout');
        }
      })
      .catch(error => console.error('Error:', error));
  });



  $('#buttonEnviar').on('click', () => {
    // mensaje enviado:
    const text = $('#textAreaMensaje').val();
    $('#textAreaMensaje').val('').on('input'); // se ejecuta a función contenida en on('input') para restablecer la altura
    agregarMensaje(text, { role: 'user' }, obtenerHora());

    activarEscribiendo();

    // respuesta:
    (autenticado ?
      apiTextProcessing.processText(text, threadId, groupId)
      : apiTextProcessing.processTextAnonimo(text, threadId)
    )
      .then(response => {
        const reversed_messagesModif = [...response.messagesModif].reverse(); // se invierte el orden
        threadId = response.threadId;
        groupId = response.groupId;
        activitiId = response.activitiId;

        reversed_messagesModif.forEach(itemData => {
          if (itemData.role === 'assistant') {
            itemData.content.forEach(v => {
              agregarMensaje(v.text.value, { role: 'assistant' }, obtenerHora(response.created_at)); // revisar obtenerHora()
            });
          }
        });
        irHaciaAbajo();
      })
      .catch(e => {
        if (e?.data?.message === 'No autorizado') { // esto no se debería producir
          throw new Error('En situaciones normales esto no debería pasar')
          agregarMensaje('Si ya tienes una cuenta por favor ingresa, si aún no la tienes presiona "Agregar Cuenta", arriba a la derecha...', { role: 'assistant' }, obtenerHora());
          // no se pueden enviar mas mensajes
          $('#textAreaMensaje').attr("disabled", true);
          $('#buttonEnviar').attr("disabled", true);
          $('#labelTextAreaMensaje').text('Inicia sesión para enviar mensajes...');

          return;
        }
        agregarMensaje('Lo siento, tube un error', { role: 'assistant' }, obtenerHora());
        console.log(e);
      })
      .finally(() => {
        desactivarEscribiendo();
      });
  });





  // para que se envíe el formulario al presionar enter
  // y inserte un salto de línea si se presiona Enter junto con Alt o Control
  $('#textAreaMensaje').on('keydown', function (e) {
    // Verificar si la tecla presionada es Enter
    if (e.key === 'Enter') {
      // Si se presiona Enter junto con Alt o Control, insertar salto de línea
      if (e.altKey || e.ctrlKey) {
        // Insertar salto de línea en la posición del cursor
        const start = this.selectionStart;
        const end = this.selectionEnd;
        const text = $(this).val();
        const before = text.substring(0, start);
        const after = text.substring(end);
        $(this).val(before + "\n" + after);
        // Colocar el cursor después del salto de línea
        this.selectionStart = this.selectionEnd = start + 1;
      } else {
        // Si solo se presiona Enter, prevenir el comportamiento por defecto (salto de línea)
        e.preventDefault();
        // Simular un click en el botón de enviar
        $('#buttonEnviar').click();
      }
    }
  });

  // para la altura de textAreaMensaje
  $('#textAreaMensaje').on('input', function () {
    // Resetea la altura para obtener el scrollHeight correcto
    this.style.height = 'auto';

    // Ajusta la altura al scrollHeight del textarea
    this.style.height = (this.scrollHeight) + 'px';
  });

  $('#contactForm').on('submit', function (e) {
    e.preventDefault(); // Evita el envío del formulario de la manera tradicional

    // Recolecta los datos del formulario
    var name = $('#floatingName').val().trim();
    var email = $('#floatingEmail').val().trim();
    var phone = $('#floatingPhone').val().trim();
    var message = $('#floatingTextarea').val().trim();
    var subject = 'Consulta desde el formulario de contacto';

    // Valida los campos aquí si es necesario

    // Usa la función sendContactForm para enviar los datos al servidor
    apiContact.sendContactForm(name, email, subject, message, phone)
      .then(response => {
        // Oculta el formulario
        $('#contactForm').hide();
        // Muestra el mensaje de agradecimiento
        $('#thankYouMessage').show();
      })
      .catch(error => {
        // Maneja los errores
        console.error('Error al enviar el mensaje:', error);
        // Aquí puedes mostrar un mensaje de error
      });
  });



});





let autenticado; // en casos donde hay otras validaciones sirve para tomar decición sin consultar el backend
function mostrarInfo_publica(textos) {
  autenticado = false;
  console.log('User is not authenticated');
  mostrarInfo_publica_en_navbar();
  $('#divListaGrupos').hide();
  $('#divChat').show();
  $('#textAreaMensaje').removeAttr("disabled").trigger("focus");
  $('#buttonEnviar').removeAttr("disabled");
  $('#labelTextAreaMensaje').text('Envíame un mensaje:');

  $('#imgGrupo').attr('src', gptina);
  $('#spanNombreFrupo').text('Gptina');

  $('#mensaje_inicial').hide();
  $("#hora_incial").hide();

  // Comprobar si el array está vacío y asignar el valor predeterminado si es necesario
  if (!textos || textos.length === 0) {
    textos = [
      `¡Hola! Soy Gptina, un asistente para registrar las deudas entre amigos de forma simple y eficiente, ya
      sea para un asado,
      una cena en un restaurante o durante un viaje, sin importar cuántas transacciones se hayan acumulado.
      Simplemente comunícame los detalles y me encargaré de los cálculos.`,
      '¿En qué te puedo que ayudar?'
    ];
  }

  vaciarChat();

  // Función para manejar la escritura lenta de cada mensaje
  function manejarEscrituraLenta(i) {
    if (i < textos.length) {
      let elemento = agregarMensaje('', { role: "assistant" }, obtenerHora());
      escrituraLenta(elemento, textos[i], 10).then(() => {
        manejarEscrituraLenta(i + 1); // Llamar a la siguiente iteración
      });
    }
  }

  // Iniciar la escritura lenta para el primer mensaje
  manejarEscrituraLenta(0);

  $("#btnAtras").hide();
  $("#spanNombreFrupo").text('Gptina');
  $("#estado").text('Online');
}


function mostarInfo_usuario(response) {
  autenticado = true
  $('#textAreaMensaje').removeAttr("disabled");
  $('#buttonEnviar').removeAttr("disabled");
  $('#labelTextAreaMensaje').text('Envíame un mensaje:');

  let loginData = response.user;
  console.log('User is authenticated');
  mostarInfo_usuario_en_navbar(loginData);
  cargarGrupos()
    .then(infoGgrupos => {
      if (infoGgrupos.cantidadGruposCargados === 0) {
        // se crea un nuevo grupo
        apiGroups.createGroup({
          name: 'Primer grupo',
        })
          .then(cargarGrupos)
          .then((infoGgrupos) => {
            // se muestra el nuevo grupo
            infoGgrupos.ultimoListItem.click();
          })

      } else { // hay almeos 1
        cargarGrupos()
      }
    });
};








function irHaciaAbajo() {
  var $miElemento = $('#bodyWhatsapp');
  // Calculamos la altura total del contenido, incluyendo lo que no se ve en pantalla
  var alturaTotalContenido = $miElemento.prop('scrollHeight');
  // Animamos el scroll hacia abajo hasta el final
  $miElemento.animate({
    scrollTop: alturaTotalContenido
  }, {
    duration: 500,
    queue: false
  }); // 500 milisegundos = 1 segundo para la animación
}

function agregarMensaje(text, { role }, hora) {
  let divMensaje = $(`
    <div class="message ${role === 'assistant' ? 'recibido' : 'enviado'}">
      <div>
        ${text}
      </div>
      <span class="message-time">${hora || ''} <i class="bi bi-check2-all text-dark"></i></span>
    </div>
  `)
  $('#bodyWhatsapp').append(divMensaje);
  irHaciaAbajo();
  return divMensaje
}

function vaciarChat() {
  $('#bodyWhatsapp').empty();
}

function MostrarModal1({
  Titulo, // ej: 'Titulo de ejemplo'
  cuerpoHtml, // ej: '<p>texto de ejemplo</p>'
  MostrarBotonCerrar, // true or false. boton a la deracha en el encabezado
  ocultarAlPresionarAfuera, // true or false. Indica si se debe cerrar el modal al presionar afuera
  BtnPrimaryText, // ej: 'Aceptar'
  BtnSecondaryText, // ej: 'Cancelar'
  OnBtnPrimaryClick, // función ques e ejecuta al presionar boton primario. Si no se especifica una función se cierra el modal. Al presionar el boton secundario sienpre se cierra 
}) {

  let modalJq = $("#Modal1")
  let modal = new Modal(modalJq[0], { backdrop: ocultarAlPresionarAfuera, focus: true, keyboard: true });
  //if (ocultarAlPresionarAfuera) Modal1.modal({ backdrop: ocultarAlPresionarAfuera });
  modal.show();
  modalJq.find(".modal-title").text(Titulo);
  modalJq.find(".modal-body").html(cuerpoHtml);
  let BtnSecondary = modalJq.find(".modal-footer button:first-child"),
    BtnPrimary = modalJq.find(".modal-footer button:nth-child(2)");

  BtnPrimaryText ? BtnPrimary.show().text(BtnPrimaryText) : BtnPrimary.hide(); // si BtnPrimaryText tiene un texto se muestra
  BtnSecondaryText ? BtnSecondary.show().text(BtnSecondaryText) : BtnSecondary.hide();
  if (BtnPrimaryText || BtnSecondaryText) modalJq.find(".modal-footer").show();
  else modalJq.find(".modal-footer").hide();

  MostrarBotonCerrar ? modalJq.find(".modal-header>button").show() : modalJq.find(".modal-header>button").hide();

  BtnPrimary.off("click");
  if (typeof OnBtnPrimaryClick === "function") {
    BtnPrimary.on('click', () => {
      OnBtnPrimaryClick(); // se ejecuta la función
      modal.hide(); // se cierra el modal
    });
  } else {
    // si no se especifica una función en OnBtnPrimaryClick se cierra el modal al hacer click
    BtnPrimary.on('click', () => modal.hide());  // se cierra el modal     
  }
  return modal
}

async function cargarGrupos() {
  return apiGroups.getGroups()
    .then(response => {
      // Vaciamos la lista actual para evitar duplicados
      // $('#whatsapp_groups').empty();
      $('#whatsapp_groups').find('li:not(#create-group-item)').remove();

      // Iteramos sobre cada grupo y construimos el HTML para cada elemento de la lista
      let listItem;
      response.forEach(group => {
        listItem = $(`
        <li class="list-group-item chat-item" data-group-id="${group.id}">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img class="rounded-circle chat-image" src="${group.image || imgGrupo1}" alt="Group Image">
              <div class="chat-info ms-2">
                <div class="chat-name">${group.name}</div>
                <div class="chat-preview">${group.previewMessage || ''}</div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="chat-time me-2">${group.lastMessageTime || ''}</div>
              <i class="bi bi-three-dots-vertical"></i>
            </div>
          </div>
        </li>
      `);

        // Añadimos el evento click al elemento de la lista
        listItem.on('click', function () {
          vaciarChat();
          // Removemos la clase 'selected' de cualquier otro elemento que la tenga
          $('.list-group-item').removeClass('selected');
          // Añadimos la clase 'selected' al elemento clickeado
          $(this).addClass('selected');
          // Llamamos a la función groupDetail con el ID del grupo
          groupId = group.id// $(this).data('group-id')
          apiGroups.getGroupWithMessages(groupId)
            .then(response => {
              if (response.messages.length === 0) {
                // Se agrega un primer mensaje
                agregarMensaje('¡Hola! Soy Gptina, un asistente para registrar las deudas entre amigos de forma simple y eficiente.<br>¿En qué te puedo ayudar?', { role: 'assistant' }, obtenerHora());
              } else {
                const mensajesOrdenados = response.messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                mensajesOrdenados.forEach(m => {
                  agregarMensaje(m.content, { role: m.role }, obtenerHora(m.created_at));
                });
              };
            }).catch(error => {
              // Manejar el error adecuadamente
              if (error.status === 401) { // no autorizado

                //revisar!!
                const loginModal = $('#loginModal');
                let modal = new Modal(loginModal[0]);
                //if (ocultarAlPresionarAfuera) Modal1.modal({ backdrop: ocultarAlPresionarAfuera });
                modal.show();


              } else {
                console.error('Error al obtener el grupo y sus actividades:', error);
              }
            });
          // se muestran el chat en lugar de los grupos
          $('#divListaGrupos').hide();
          $('#divChat').show();
          $('#textAreaMensaje').trigger("focus");
          // se muestra el nombre e imagen del grupo
          $('#imgGrupo').attr('src', group.image || imgGrupo1);
          $('#spanNombreFrupo').text(group.name);
          // 
        });

        // Añadimos el elemento de la lista al contenedor
        $('#whatsapp_groups').append(listItem);
      });
      // se muestran los grupos en lugar del chat
      $('#divListaGrupos').show();
      $('#divChat').hide();


      // lo siguiente se usa para decidir si crear un nuevo grupo automaticamente
      // y mostrar mostrarlo después de acerlo
      return { cantidadGruposCargados: response.length, ultimoListItem: listItem };

    })
    .catch(error => {
      console.error('Error al cargar los grupos:', error);
    });
}



function habilitarNuevoGrupo() {


  let modalJq = $("#nuevoGrupoModal")
  let modal = new Modal(modalJq[0], { backdrop: true, focus: true, keyboard: true });

  $('#create-group-item').off('click').on('click', function () {
    //if (ocultarAlPresionarAfuera) Modal1.modal({ backdrop: ocultarAlPresionarAfuera });
    modal.show();
    // Asignar el foco cuando el modal se haya mostrado completamente
    // modalJq.on('shown.bs.modal', function () { // no funciona
    modalJq[0].addEventListener('shown.bs.modal', () => {
      $('#input_nombreDelGrupo').trigger("focus");
    })
  });


  // Función que se ejecutará cuando se presione Enter o se haga clic en Aceptar
  function ejecutarAccion() {
    apiGroups.createGroup({
      name: $('#input_nombreDelGrupo').val(),
    })
      .then(cargarGrupos)
      .then(() => {
        modal.hide();
      })

  }

  // Evento para detectar la tecla Enter en el input
  $('#input_nombreDelGrupo').on('keypress', function (e) {
    if (e.which == 13) { // 13 es el código de la tecla Enter
      e.preventDefault(); // Prevenir el comportamiento por defecto del formulario
      ejecutarAccion();
    }
  });

  // Evento para detectar el clic en el botón Aceptar
  $('#btnNuevoGrupo').on('click', function () {
    ejecutarAccion();
  });


}

// Función para abrir el formulario de creación de grupos
// function openCreateGroupForm() {
//   // Aquí puedes implementar la lógica para mostrar un formulario o modal
//   console.log('Abrir interfaz para crear un nuevo grupo');
//       // Alternar la visibilidad del texto y el formulario
//       $(this).find('.create-group-text').toggle();
//       $(this).find('.create-group-form').toggle();
// }