function getOrigen() {
    
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        return 'http://localhost:3000'
    } else {
        return 'https://api.gptina.cl'
    }
}

async function request(endpoint, method, data) {

    const url = getOrigen() + endpoint;

    const headers = {};

    if (!(data instanceof FormData)) {
        headers["Content-Type"] = "application/json";
    }

    let withCredentials = true;
    const options = {
        method,
        headers,
        credentials: withCredentials ? 'include' : 'same-origin'
    };

    if (method !== 'GET' && method !== 'HEAD' && data) {
        options.body = data instanceof FormData ? data : JSON.stringify(data);
    }

    const response = await fetch(url, options);

    if (!response.ok) {
        const errorData = await response.json();
        const error = new Error(`status: ${response.status}, message: ${response.message} `);
        error.data = errorData;
        throw error;
    }

    return response.json();
}

export {request, getOrigen};